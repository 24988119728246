<template>
  <div class="home">
    <b-container class="mt-10" fluid>
      <h3 class="py-2">
        <span class="text-capitalize">&nbsp;&nbsp;</span>
      </h3>
      <hr />
      <b-row>
        <b-col md="12">
          <div class="my-cart mb-4 mt-5">
            <h3>Select Prepared Excel Sheet</h3>
            <table class="form-table mt-4">
              <tr>
                <th>Select File (Format: ItemNo | Colour | Quantity)</th>
                <td>
                  <input type="file" @change="parseFile" ref="file" />
                </td>
              </tr>

              <tr>
                <th>Enter Sheet Name</th>
                <td>
                  <b-input-group>
                    <b-form-input
                      placeholder="Sheet Name"
                      v-model="sheetName"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        size="sm"
                        text="Button"
                        variant="success"
                        @click="getDetails"
                      >
                        Proceed
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </td>
              </tr>
            </table>

            <b-container
              fluid
              :style="{
                minHeight: '200px',
                minWidth: '100%',
                display: 'flex',
                placeItems: 'center'
              }"
              v-if="gettingDetails"
            >
              <br />
              <b-spinner label="Loading..." style="margin: 0 auto"></b-spinner>
            </b-container>

            <table
              class="vehicle-table table b-table table-striped table-hover mt-4"
              v-if="!gettingDetails && gotDetails"
            >
              <thead>
                <tr>
                  <th>Item No</th>
                  <th>Colour</th>
                  <th>Quantity</th>
                  <th>Description</th>
                  <th>Model No</th>
                  <th>Model Name</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in items" :key="item.ItemNo">
                  <td>
                    {{ item.ItemNo }}
                  </td>
                  <td>
                    {{ item.Colour }}
                  </td>
                  <td>
                    {{ item.Quantity }}
                  </td>
                  <td>
                    {{ item.Description }}
                  </td>
                  <td>
                    {{ item.ModelNo }}
                  </td>
                  <td>
                    {{ item.ModelName }}
                  </td>
                </tr>
              </tbody>

              <tfoot>
                <tr>
                  <td colspan="6">
                    <b-button variant="danger" @click="placeOrder">
                      <template v-if="placingOrder">
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                      </template>
                      <template v-else>
                        Place Order
                      </template>
                    </b-button>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
// import config from "../config";
import VehicleService from "../service/vehicle";
// import { getData } from "./../utils/storage";
import readXlsxFile from "read-excel-file";

export default {
  data() {
    return {
      items: [],
      sheetName: "",
      gettingDetails: false,
      placingOrder: false,
      gotDetails: false
    };
  },
  created() {
    // const loggedInUser = getData("userInfo");
  },
  computed: {},
  methods: {
    showToast(text, variant = "danger") {
      this.$bvToast.toast(text, {
        title: `Error`,
        variant: variant,
        solid: true
      });
    },

    showSuccess(text, variant = "success") {
      this.$bvToast.toast(text, {
        title: `Success`,
        variant: variant,
        solid: true
      });
    },

    parseFile() {
      // console.log(e);
      //TODO: Parse File

      readXlsxFile(this.$refs.file.files[0])
        .then((rows) => {
          console.log(rows);
          this.items = [];

          for (let index = 1; index < rows.length; index++) {
            const row = rows[index];
            this.items.push({
              ItemNo: row[0],
              Colour: row[1],
              Quantity: row[2]
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.showToast("File not supported!");
          this.$refs.file.value = "";
        });
    },

    getDetails() {
      if (this.items.length === 0) {
        this.showToast("Choose a file first");
        return;
      } else if (!this.sheetName) {
        this.showToast("Please enter Sheet Name");
        return;
      }

      this.gettingDetails = true;
      VehicleService.getDetails(this.items)
        .then((res) => {
          // this.items = res.data.Data;
          res.data.Data.forEach((item, i) => {
            this.items[i].Description = item.Description;
            this.items[i].ModelNo = item.ModelNo;
            this.items[i].ModelName = item.ModelName;
          });
          this.gotDetails = true;
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.gettingDetails = false;
        });
    },
    placeOrder() {
      const postItems = this.items.map((item) => ({
        ItemNo: item.ItemNo,
        Colour: item.Colour,
        Quantity: item.Quantity
      }));

      const payload = { info: postItems };
      console.log(payload);

      this.placingOrder = true;
      VehicleService.placeOrderVehicle(payload)
        .then((res) => {
          // console.log(res.data);
          this.showSuccess(res.data.Message);
          this.resetForm();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.placingOrder = false;
        });
    },

    resetForm() {
      this.$refs.file.value = "";
      this.items = [];
      this.gotDetails = false;
      this.sheetName = "";
    }
  },
  watch: {},
  mounted() {
    // this.parseFile("a");
    // this.getDetails();
  }
};
</script>
<style>
.pointer:hover {
  cursor: pointer;
  text-decoration: underline;
}

.toast {
  font-size: 1.5rem;
}

.progress {
  font-size: 1.2rem;
}

.form-table {
  width: 100%;
  max-width: 800px;
}

.form-table td,
.form-table th {
  padding: 11px;
}

.vehicle-table {
  width: 100%;
}
</style>
